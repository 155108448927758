import { Switch } from '@/components/ui/switch'
import { cls } from '@/utils'
import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect } from 'react'
import ChevronDoubleRight from '@haiper/icons-svg/icons/outline/chevron-double-right.svg'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import GSUpload from '@/components/gs-upload'
import { InnerUploadFile, ModelVersion, ThreeStage } from '@/types'
import IconFirstFrame from '@/public/assets/first-frame.svg'
import IconMiddleFrame from '@/public/assets/middle-frame.svg'
import IconLastFrame from '@/public/assets/last-frame.svg'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'

export interface KeyframeConditioningProps {
  className?: string
  files: ThreeStage<InnerUploadFile | null>
  setFiles: Dispatch<SetStateAction<ThreeStage<InnerUploadFile | null>>>
  uploading: ThreeStage<boolean>
  setUploading: Dispatch<SetStateAction<ThreeStage<boolean>>>
  enableAfc?: boolean
  setEnableAfc?: Dispatch<SetStateAction<boolean>>
  beforeUpload?: (file: File) => Promise<boolean>
  extra?: ReactElement
}

export default function KeyframeConditioning({
  files,
  setFiles,
  uploading,
  setUploading,
  enableAfc,
  setEnableAfc,
  extra,
  beforeUpload,
  className,
}: KeyframeConditioningProps) {
  const { isBelowMd } = useBreakpoint('md')
  const creationInput = useAtomValue(creationInputAtom)
  const isModel2 = creationInput?.version === ModelVersion.TWO

  const handleAfcChange = useCallback(
    (val: boolean) => {
      if (!val) {
        setFiles?.((old) => ({ ...old, second: null, third: null }))
      }
      setEnableAfc?.(val)
    },
    [setEnableAfc, setFiles],
  )

  useEffect(() => {
    if (isModel2) {
      handleAfcChange(false)
    }
  }, [isModel2, handleAfcChange])

  const iconStyle = cls('size-4', enableAfc ? 'text-icon-subdued' : 'text-icon-disable')
  const seperator = (
    <div className='flex items-center justify-center h-full px-0.5 md:px-4' aria-label='seperator'>
      <ChevronDoubleRight className={iconStyle} />
    </div>
  )

  const uploadStyle = cls(
    'w-0 flex-1 xs:w-42 max-w-42 h-[98px] rounded-xl border border-solid border-b-2 border-border bg-surface overflow-hidden',
  )

  return (
    <div className={cls('flex w-full justify-center items-center', className)} aria-label='keyframe-conditioning'>
      <div className='flex flex-col justify-center w-full [@media(min-width:600px)]:w-max'>
        <div className='flex flex-col gap-1.5' aria-label='title'>
          <div className='flex items-center gap-2 text-body-md tracking-15' aria-label='keyframe conditioning switch'>
            <span>Keyframe Conditioning</span>
            <div className='relative flex items-center'>
              <Switch
                className=''
                size='sm'
                checked={enableAfc}
                disabled={isModel2}
                onCheckedChange={handleAfcChange}
              />
            </div>
            {isModel2 && <span className='text-body-sm text-text-subdued'>Coming soon for Haiper 2.1</span>}
          </div>
          <ol
            className='flex flex-col m-0 text-body-md text-text-subdued tracking-15 bg-surface-subdued py-2 px-7 space-y-1 w-full rounded-md'
            aria-label='description'
          >
            <li className='m-0 text-body-sm'>
              Upload an image as a keyframe at the beginning, middle or end of the video.
            </li>
            <li className='m-0 text-body-sm'>
              If you upload multiple images, make sure objects in the images are consistent.
            </li>
          </ol>
        </div>
        <div className='flex md:h-24 items-center w-full mt-3' aria-label='uploads'>
          <GSUpload
            fileType='image'
            className={uploadStyle}
            file={files.first}
            uploading={uploading.first}
            setUploading={(val) => setUploading((old) => ({ ...old, first: val }))}
            emptyText={isBelowMd ? 'First frame' : 'First frame image'}
            emptyIcon={IconFirstFrame}
            beforeUpload={beforeUpload}
            onChange={(file) => setFiles((old) => ({ ...old, first: file }))}
          />
          {seperator}
          <GSUpload
            fileType='image'
            className={uploadStyle}
            file={files.second}
            uploading={uploading.second}
            setUploading={(val) => setUploading((old) => ({ ...old, second: val }))}
            emptyText={isBelowMd ? 'Middle frame' : 'Middle frame image'}
            emptyIcon={IconMiddleFrame}
            disabled={!enableAfc}
            beforeUpload={beforeUpload}
            onChange={(file) => setFiles((old) => ({ ...old, second: file }))}
          />
          {seperator}
          <GSUpload
            fileType='image'
            className={uploadStyle}
            file={files.third}
            uploading={uploading.third}
            setUploading={(val) => setUploading((old) => ({ ...old, third: val }))}
            emptyText={isBelowMd ? 'Last frame' : 'Last frame image'}
            emptyIcon={IconLastFrame}
            disabled={!enableAfc}
            beforeUpload={beforeUpload}
            onChange={(file) => setFiles((old) => ({ ...old, third: file }))}
          />
        </div>
        {extra ?? null}
      </div>
    </div>
  )
}
