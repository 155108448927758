import Button from '@/components/button'
import useModelVersion from '@/hooks/useModelVersion'
import { ModelVersion } from '@/types'
import { cls } from '@/utils'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-down-small.svg'
import { useMemo, useRef } from 'react'
import Popover from '@/components/popover'
import Checkbox from '@/components/checkbox'
import { PopoverClose } from '@/components/ui/popover'
import BadgeNew from '@/components/badges/new'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'

export interface ModelVersionPickerProps {
  className?: string
}

export default function ModelVersionPicker({ className }: ModelVersionPickerProps) {
  const { mode } = useAtomValue(creationInputAtom)
  const { version, setVersion, availableVersions, versionStr, version2Str } = useModelVersion()

  const options = useMemo(() => {
    return [
      {
        label: version2Str,
        value: ModelVersion.TWO,
        disabled: !availableVersions.includes(ModelVersion.TWO),
        isNew: true,
      },
      {
        label: 'Haiper 1.5',
        value: ModelVersion.ONE,
      },
    ].filter(({ value }) => availableVersions.includes(value))
  }, [availableVersions, version2Str])

  const closeHandleRef = useRef<HTMLButtonElement>(null)

  if (availableVersions.length <= 1 && availableVersions?.[0] !== ModelVersion.TWO) {
    return null
  }

  return (
    <Popover
      trigger={
        <Button
          aria-label='model version'
          type='button'
          variant='outline'
          className={cls(
            'relative group flex items-center justify-center h-10 p-2 gap-3 text-body-md cursor-pointer select-none text-text rounded-[48px] border border-b-2 border-solid border-border transition-all duration-200 ease-in-out hover:border-border-hover active:border-border-hover bg-surface hover:bg-surface-hover active:bg-surface-hover hover:shadow-[0_0_10px_0_rgba(250,250,250,0.2)] active:shadow-[0_0_10px_0_rgba(250,250,250,0.2)]',
            'items-center relative w-[124px]',
            className,
          )}
          data-testid='creation-model-version-button'
        >
          <div className='flex items-center gap-1'>
            <span aria-label='modeName' className='text-body-md pl-2 pr-1 tracking-15'>
              {versionStr}
            </span>
            <ChevronDown className='size-6 text-icon' />
            {version === ModelVersion.TWO && (
              <BadgeNew className='absolute right-0 top-0 translate-y-[-6px] bg-surface' />
            )}
          </div>
        </Button>
      }
    >
      <div className='flex flex-col gap-2 w-full'>
        <span className='text-body-lg tracking-32'>Model Version</span>
        <div className='flex flex-col gap-3 w-full relative'>
          {options.map(({ label, value, disabled, isNew }) => {
            const checked = value === version
            return (
              <div
                key={value}
                className={cls(
                  'p-3 pr-4 h-14 flex gap-2.5 items-center cursor-pointer border border-b-2 border-border rounded-lg',
                  disabled && 'opacity-50 cursor-not-allowed',
                  checked && 'border-border-active',
                )}
                onClick={() => {
                  if (disabled) return
                  setVersion(value)
                  closeHandleRef.current?.click()
                }}
              >
                <div className='flex-1 flex items-center gap-1'>
                  <span className='px-1'>{label}</span>
                  {isNew && <BadgeNew />}
                </div>
                {checked && <Checkbox checked={value === version} className='px-1' />}
              </div>
            )
          })}
        </div>
        <PopoverClose ref={closeHandleRef} className='hidden' />
      </div>
    </Popover>
  )
}
