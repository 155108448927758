import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import Button from '@/components/button'
import { Slider, Switch } from '@lemonsqueezy/wedges'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import Input from '@/components/input'
import Popover from '@/components/popover'
import { cls, findNearestNumber, whisper } from '@/utils'
import ButtonCopy from '@/components/copy-button'
import IconClose from '@/public/assets/close-2.svg'
import { GenerationSetting, CreationModeEnum, CreditSpendSku, CameraMovement, ModelVersion } from '@/types'
import useAmplitude from '@/hooks/useAmplitude'
import IconSetting from '@haiper/icons-svg/icons/outline/slider-hor.svg'
import IconCredit from '@/public/assets/diamond.svg'
import AspectRatioOptions from '../aspect-ratio'
import { cameraMovementMap, DEFAULT_ASPECT_RATIO, TEMPLATE_CREDIT_PRICE } from '@/constants'
import IconColors from '@haiper/icons-svg/icons/outline/color-swatch.svg'
import IconPageSize from '@haiper/icons-svg/icons/outline/auto-page-size.svg'
import IconDice from '@haiper/icons-svg/icons/outline/dice-3.svg'
import IconStopWatch from '@haiper/icons-svg/icons/outline/stopwatch.svg'
import IconResolution from '@haiper/icons-svg/icons/outline/resolution.svg'
import Suggestions from '../suggestion'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { isNil } from 'lodash-es'
import useCreationSettingVisibility from '@/hooks/useCreationSettingVisibility'
import Dialog from '@/components/dialog'
import useDurationOptions from '@/hooks/useDurationOptions'
import useCurrentCreationMode from '@/hooks/useCurrentCreationMode'
import useCredit from '@/hooks/useCredit'
import IconRotate from '@haiper/icons-svg/icons/outline/rotate-3d.svg'
import CameraMovementSetting from '../camera-movement'
import useSuggestions from '@/hooks/useSuggestions'
import { useAtom, useAtomValue } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom, respectTemplateClientNameAtom } from '@/atoms'
import useModelVersion from '@/hooks/useModelVersion'
import IconVideoHighMotion from '@haiper/icons-svg/icons/outline/video-high-motion.svg'
import IconBadgeAlpha from '@/public/assets/badge-alpha.svg'
import Video from '@/components/video'

interface CreationSettingProps {
  className?: string
  settings?: GenerationSetting
  setSettings: Dispatch<SetStateAction<Partial<GenerationSetting>>>
  style?: string
  setStyle?: Dispatch<SetStateAction<string>>
}

const apgFields = [
  { label: 'Eta', key: 'apg_config_eta' },
  { label: 'Norm Threshold', key: 'apg_config_norm_threshold' },
  { label: 'Momentum', key: 'apg_config_momentum' },
  { label: 'Guidance Scale', key: 'guidance_scale' },
] as const

export function CreationSetting({ className, settings, setSettings, style, setStyle }: CreationSettingProps) {
  const { track } = useAmplitude()

  const { data: currentCreationMode } = useCurrentCreationMode()
  const suggestions = useSuggestions()
  const styleName = useMemo(() => {
    const styleRecord = suggestions?.find((item) => item.key === style)
    return styleRecord?.label ?? style
  }, [suggestions, style])

  const { isBelowMd } = useBreakpoint('md')
  const { version } = useModelVersion()

  const resolutions = useMemo(() => {
    return version === ModelVersion.TWO ? [720, 1080, 2160] : [720, 1080]
  }, [version])
  const activeTemplateId = useAtomValue(activeTemplateIdAtom)

  const [respectTemplateClientName, setRespectTemplateClientName] = useAtom(respectTemplateClientNameAtom)

  const isMotionLevelEnabled = Boolean(settings?.motion_level && settings.motion_level !== -1)
  const motionLevel = settings?.motion_level ?? -1
  const isHD =
    settings?.resolution &&
    ((currentCreationMode?.mode === CreationModeEnum.CreateImg && settings?.resolution >= 1080) ||
      (currentCreationMode?.mode !== CreationModeEnum.CreateImg && settings?.resolution >= 720))

  type PopoverKey =
    | 'seed'
    | 'motion_level'
    | 'duration'
    | 'resolution'
    | 'aspect_ratio'
    | 'styles'
    | 'camera-movement'
    | 'motion-mode'
    | 'apg'
    | 'full'
  const [activePopoverKey, setActivePopoverKey] = useState<PopoverKey | null>(null)

  const hideAllIndividualPopover = useCallback(() => {
    setActivePopoverKey((old) => {
      return old === 'full' ? old : null
    })
  }, [])

  const formatAPGValues = useCallback(() => {
    for (const field of apgFields) {
      if (typeof settings?.[field.key] === 'string') {
        if (settings[field.key] === ('' as any)) {
          settings[field.key] = undefined
        } else {
          settings[field.key] = Math.round(Number(settings[field.key]) * 100) / 100
        }
      }
    }
  }, [settings])

  const {
    showMotionLevel,
    showDuration,
    showFakeDuration,
    showFakeAspectRatio,
    showCameraMovement,
    showResolution,
    showAspectRatio,
    showMotionMode,
    showStyles,
    showTemplateClient,
    showFastGen,
    showAPG,
  } = useCreationSettingVisibility()

  const { getCreditPrice } = useCredit()

  const durationOptions = useDurationOptions()

  const renderCredit = useCallback(
    (item: CreditSpendSku) => {
      const amount = activeTemplateId ? TEMPLATE_CREDIT_PRICE : item.amount
      if (amount === 0) {
        return (
          <div className='h-6 px-3 text-body-sm leading-6 rounded-sm bg-surface-hover flex items-center min-w-12 text-center justify-center'>
            Free
          </div>
        )
      }

      return (
        <div className='h-6 px-1 text-body-sm leading-6 rounded-sm bg-surface-hover flex items-center min-w-12 justify-center gap-1'>
          <IconCredit className='size-4' />
          <span>{amount}</span>
        </div>
      )
    },
    [activeTemplateId],
  )

  const resolutionOptions = useMemo(() => {
    if (currentCreationMode?.mode === CreationModeEnum.CreateImg) {
      return resolutions.map((resolution) => {
        return {
          // label: `${resolution}p`,
          label: (
            <div className='w-full flex items-center justify-between'>
              <span>
                {resolution}p{resolution === 2160 ? ' (4K)' : ''}
              </span>
              <div className='h-6 px-1 text-body-sm leading-6 rounded-sm bg-surface-hover flex items-center min-w-12 justify-center gap-1'>
                <IconCredit className='size-4' />
                <span>
                  {getCreditPrice({
                    mode: currentCreationMode?.mode,
                    duration: 0,
                    resolution,
                    version,
                  })}
                </span>
              </div>
            </div>
          ),
          value: resolution,
        }
      })
    }
    return [
      {
        label: 'SD (Enhanced motion)',
        value: 540,
      },
      {
        label: 'HD (Higher fidelity)',
        value: 720,
      },
    ]
  }, [currentCreationMode, getCreditPrice, version, resolutions])

  const motionModeOptions = useMemo(() => {
    return [
      {
        label: (
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col'>
              <span className='text-body-lg tracking-32'>Standard</span>
              <span className='text-body-md tracking-15 text-text-subdued'>For videos with high fidelity</span>
            </div>
            <div className='w-40 aspect-video shrink-0'>
              <video
                autoPlay
                muted
                loop
                className='size-full aspect-video rounded-md'
                src='https://scontent.haiper.ai/webapp/videos/mode/standard.mp4'
                poster='https://scontent.haiper.ai/webapp/videos/mode/standard.jpg'
              />
            </div>
          </div>
        ),
        value: true,
      },
      {
        label: (
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col'>
              <span className='text-body-lg tracking-32 flex gap-2 items-center'>
                Smooth
                <IconBadgeAlpha />
              </span>
              <span className='text-body-md tracking-15 text-text-subdued'>For videos with extensive motion</span>
            </div>
            <div className='w-40 aspect-video shrink-0'>
              <video
                autoPlay
                muted
                loop
                className='size-full aspect-video rounded-md'
                src='https://scontent.haiper.ai/webapp/videos/mode/smooth2.mp4'
                poster='https://scontent.haiper.ai/webapp/videos/mode/smooth2.jpg'
              />
            </div>
          </div>
        ),
        value: false,
      },
    ]
  }, [])

  const respectTemplateClientNameOptions = useMemo(() => {
    return [
      {
        label: 'Replicate',
        value: false,
      },
      {
        label: 'Haiper',
        value: true,
      },
    ]
  }, [])

  // const useDialog = isBelowMd
  const useDialog = isBelowMd && showStyles
  const FullSettingContainer = useDialog ? Dialog : Popover

  const fieldsetStyle = cls(
    'bg-surface rounded-md p-4 flex flex-col gap-2 last-of-type:flex-1 w-[300px] overflow-x-hidden pointer-events-auto',
    useDialog && 'w-full',
  )
  const popoverStyle = cls(
    ' p-0 w-[300px] overflow-y-auto bg-surface overflow-x-hidden',
    useDialog ? 'w-full' : ' max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-128px)]',
  )

  const renderSeedControl = (withConfirmButton: boolean) => {
    return (
      <div className={cls('', fieldsetStyle)}>
        <span className='text-body-md mb-2'>Seed</span>
        <div className='relative rounded-lg bg-surface'>
          <Input
            // name='seed'
            className={cls('pl-12 rounded-lg bg-surface', withConfirmButton && 'pr-20')}
            value={settings?.seed && String(settings.seed) !== '-1' ? settings?.seed : ''}
            autoFocus={false}
            max={Number.MAX_SAFE_INTEGER}
            placeholder='Random'
            onChange={(e: any) => {
              const value = Number(e.target.value)
              const seed = isNaN(Number(value)) || !e.target.value ? -1 : Number(value)
              const validSeed = Math.min(Number.MAX_SAFE_INTEGER, Math.max(seed, Number.MIN_SAFE_INTEGER))
              setSettings((prev) => ({ ...prev, seed: validSeed }))
            }}
          />
          <ButtonCopy
            className='absolute rounded-md h-8 left-2 top-[50%] -translate-y-2/4 md:-translate-y-2/4 p-1 border-none bg-surface disabled:bg-transparent text-text-interactive hover:opacity-90 active:opacity-90 min-w-8'
            disabled={!settings?.seed || settings.seed === -1}
            text={settings?.seed ? String(settings?.seed) : undefined}
            onCopied={(seed) => {
              track('click:creation:settings-seed-copy', { seed })
            }}
          />
          {withConfirmButton && (
            <Button
              variant='link'
              className='absolute rounded-md h-8 right-2 top-[50%] -translate-y-2/4 md:-translate-y-2/4 p-1 border-none'
              onClick={hideAllIndividualPopover}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    )
  }

  const seedControl = renderSeedControl(false)
  // const seedControlInFullSettings = renderSeedControl(false)

  const motionLevelControl = showMotionLevel && (
    <div className={cls('', fieldsetStyle)}>
      <div className='text-body-md mb-2 flex justify-between items-center w-full md:w-full'>
        <span>Motion Level</span>
        <span className='ml-auto'>
          <Switch
            checked={isMotionLevelEnabled}
            onCheckedChange={() => {
              const motionLevel = isMotionLevelEnabled ? -1 : 3
              setSettings((prev) => ({
                ...prev,
                motion_level: motionLevel,
              }))
              track('input:creation:settings-motion-level-change', {
                motion_level: motionLevel,
                source: 'switch',
              })
            }}
          />
        </span>
      </div>
      <div className='relative flex gap-2 items-center [&>:first-child]:flex-1 [&>:first-child]:min-w-0'>
        <Slider
          className='flex-1 min-w-0'
          before={1}
          after={5}
          min={1}
          showTooltip='never'
          max={5}
          disabled={!isMotionLevelEnabled}
          value={[motionLevel]}
          onValueChange={(value: number[]) => {
            const motionLevel = value[0] ?? 3
            setSettings((prev) => ({
              ...prev,
              motion_level: motionLevel,
            }))
            track('input:creation:settings-motion-level-change', {
              motion_level: motionLevel,
              source: 'slider',
            })
          }}
        />
        <Input
          // type='number'
          className='w-[44px] md:w-[44px] h-[26px] md:h-[26px] ml-2 shrink-0 rounded-lg border border-solid border-border md:border-border bg-surface md:bg-surface flex items-center justify-center text-center hide-spin md:hide-spin'
          value={motionLevel === -1 ? '' : motionLevel}
          max={5}
          min={1}
          disabled={!isMotionLevelEnabled}
          onChange={(e: any) => {
            const value = Number(e.target.value) % 10
            let motionLevel = isNaN(Number(value)) || !e.target.value ? 3 : Number(value)
            motionLevel = Math.max(1, Math.min(5, motionLevel))
            setSettings((prev) => ({
              ...prev,
              motion_level: motionLevel,
            }))
            track('input:creation:settings-motion-level-change', {
              motion_level: motionLevel,
              source: 'input',
            })
          }}
        />
      </div>
    </div>
  )

  const APGControl = showAPG && (
    <div className={cls('', fieldsetStyle)}>
      <div className='text-body-md mb-2 flex justify-between items-center w-full md:w-full'>
        <span>APG</span>
      </div>
      <div className='relative flex gap-2 items-center flex-col'>
        {apgFields.map(({ key, label }) => {
          return (
            <div key={key} className='flex items-center justify-between gap-1 w-full'>
              <span className='text-body-md'>{label}</span>
              <Input
                key={key}
                // type='number'
                className='w-30 h-[26px] md:h-[26px] ml-2 shrink-0 rounded-md border border-solid border-border md:border-border bg-surface md:bg-surface flex items-center justify-center text-center hide-spin md:hide-spin leading-5'
                value={settings?.[key] ?? ''}
                onBlur={formatAPGValues}
                // placeholder={`Input ${label}`}
                onChange={(e: any) => {
                  if (e.target.value === '') {
                    setSettings((prev) => ({
                      ...prev,
                      [key]: undefined,
                    }))
                    return
                  } else {
                    const value = e.target.value.replace(/[^\d\.]/g, '')
                    if (!isNaN(value)) {
                      setSettings((prev) => ({
                        ...prev,
                        [key]: value,
                      }))
                    }
                  }
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )

  const durationControl = showDuration && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Duration</span>
      <RadioGroup
        aria-label='Duration'
        value={String(settings?.duration || 2)}
        onValueChange={(value) => {
          const duration = Number(value)
          if (duration) {
            setSettings((prev) => ({ ...prev, duration }))
            track('input:creation:settings-duration-change', { duration })
          }
          hideAllIndividualPopover()
        }}
      >
        {durationOptions.map((duration) => {
          const id = `duration-radio-item-${duration.id}-${duration.second}`
          return (
            <div key={id} className='flex flex-row-reverse items-center w-full justify-between cursor-pointer'>
              <label htmlFor={id} className='flex-1 pl-3'>
                <div className='flex flex-1 min-w-[232px] justify-between'>
                  <span>{duration.second + 's'}</span>
                  {renderCredit(duration)}
                </div>
              </label>
              <RadioGroupItem
                key={[duration.second, duration.id].join('-')}
                id={id}
                value={duration.second + ''}
                checked={String(settings?.duration || 2) === String(duration.second)}
              />
            </div>
          )
        })}
      </RadioGroup>
    </div>
  )

  const resolutionControl = showResolution && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Resolution</span>
      <RadioGroup
        aria-label='Resolution'
        value={String(settings?.resolution ?? resolutionOptions[0]?.value)}
        onValueChange={(resolution) => {
          if (resolution) {
            setSettings((prev) => ({
              ...prev,
              resolution: Number(resolution),
            }))
          }
          hideAllIndividualPopover()
        }}
      >
        {resolutionOptions.map((resolution) => {
          const id = `resolution-radio-${resolution.value}`
          return (
            <div key={id} className='flex items-center justify-between'>
              <div className='flex flex-row-reverse items-center w-full'>
                <label htmlFor={id} className='flex-1 pl-3'>
                  {resolution.label}
                </label>
                <RadioGroupItem
                  key={[resolution.label, resolution.value].join('-')}
                  id={id}
                  value={String(resolution.value)}
                  checked={String(settings?.resolution) === String(resolution.value)}
                />
              </div>
            </div>
          )
        })}
      </RadioGroup>
    </div>
  )

  const motionModeValue = String(settings?.use_ff_cond ?? false)

  const motionModeControl = showMotionMode && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Mode</span>
      <RadioGroup
        aria-label='Motion Mode'
        value={motionModeValue}
        className='gap-6'
        onValueChange={(val) => {
          if (val) {
            setSettings((prev) => ({
              ...prev,
              use_ff_cond: val === 'true',
            }))
          }
          hideAllIndividualPopover()
        }}
      >
        {motionModeOptions.map((motionMode) => {
          const id = `motion-mode-radio-${motionMode.value}`
          return (
            <div key={id} className='flex items-center justify-between cursor-pointer'>
              <div className='flex flex-row-reverse items-start w-full'>
                <label htmlFor={id} className='flex-1 pl-3'>
                  {motionMode.label}
                </label>
                <div className='size-6 shrink-0 flex items-center justify-center'>
                  <RadioGroupItem
                    key={[motionMode.label, motionMode.value].join('-')}
                    id={id}
                    className='cursor-pointer'
                    value={String(motionMode.value)}
                    checked={motionModeValue === String(motionMode.value)}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </RadioGroup>
    </div>
  )

  const templateClientControl = showTemplateClient && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Template Client</span>
      <RadioGroup
        aria-label='Template Client'
        value={String(respectTemplateClientName)}
        onValueChange={(value) => {
          setRespectTemplateClientName(value === 'true')
          hideAllIndividualPopover()
        }}
      >
        {respectTemplateClientNameOptions.map((option) => {
          const id = `template-client-name-radio-${option.value}`
          return (
            <div key={id} className='flex items-center justify-between'>
              <div className='flex flex-row-reverse items-center w-full'>
                <label htmlFor={id} className='flex-1 pl-3'>
                  {option.label}
                </label>
                <RadioGroupItem
                  key={[option.label, option.value].join('-')}
                  id={id}
                  value={String(option.value)}
                  checked={String(respectTemplateClientName) === String(option.value)}
                />
              </div>
            </div>
          )
        })}
      </RadioGroup>
    </div>
  )

  const aspectRatioControl = showAspectRatio && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Aspect Ratio</span>
      <AspectRatioOptions
        value={settings?.aspect_ratio ?? null}
        onChange={(value) => {
          setSettings((prev) => ({ ...prev, aspect_ratio: value }))
          hideAllIndividualPopover()
        }}
      />
    </div>
  )

  const stylesControl = showStyles && (
    <div className={cls('', fieldsetStyle)}>
      <span className='text-body-md mb-2'>Styles</span>
      <Suggestions
        value={style}
        onChange={(value) => {
          setStyle?.(value)
          hideAllIndividualPopover()
        }}
      />
    </div>
  )

  const cameraMovementControl = showCameraMovement && (
    <CameraMovementSetting
      className={fieldsetStyle}
      value={settings?.camera_movement}
      onChange={(value: CameraMovement) => {
        setSettings((prev) => ({ ...prev, camera_movement: value }))
        hideAllIndividualPopover()
      }}
    />
  )

  // test only
  const fastGenControl = showFastGen ? (
    <div aria-label='Fast Gen' className={cls(fieldsetStyle, 'flex flex-row justify-between')}>
      <span className='text-body-md mb-2'>Fast Gen</span>
      <Switch
        checked={settings?.fast_gen ?? false}
        onCheckedChange={(v) => {
          setSettings((prev) => ({ ...prev, fast_gen: v }))
        }}
      />
    </div>
  ) : null

  const iconButtonStyle = cls(
    'my-0 p-1 shrink-0 size-8 h-8 rounded-md inline-flex items-center justify-center border border-border text-text flex items-center gap-0',
  )

  const hasSeed = !isNil(settings?.seed) && settings && String(settings?.seed) !== '-1'

  const getPopoverOpen = (key: PopoverKey) => {
    return activePopoverKey === key ? (key === 'full' ? true : undefined) : false
  }

  const seedActive = hasSeed

  const fullSettingsColumnCount = isBelowMd ? 1 : 1 + (showCameraMovement ? 1 : 0) + (showStyles ? 1 : 0)
  const showAspectRatioInFirstColumn = fullSettingsColumnCount >= 3 && showAspectRatio
  const showCameraMovementInFirstColumn = fullSettingsColumnCount >= 3 && showCameraMovement
  const showFastGenInFirstColumn = fullSettingsColumnCount >= 3 && showFastGen

  const fullSettingsControl = (
    <div className='flex flex-col gap-2'>
      <div className={cls('flex items-center justify-between', useDialog && 'hidden')}>
        <div className='text-heading-lg font-bold pl-2 leading-6 tracking-45' aria-label='title'>
          Settings
        </div>
        <Button
          className='p-0 aspect-square flex items-center justify-center bg-transparent hover:bg-surface-hover hover:opacity-80'
          variant='transparent'
          onClick={() => setActivePopoverKey(null)}
        >
          <IconClose className='size-8 text-icon' />
        </Button>
      </div>
      <div
        className={cls(
          'flex gap-2 w-full',
          !showMotionLevel && !showDuration && !showResolution && !showAspectRatio && 'flex-col-reverse',
        )}
      >
        {showCameraMovementInFirstColumn || showAspectRatioInFirstColumn ? (
          <div className='flex flex-col gap-2'>
            {showCameraMovementInFirstColumn ? cameraMovementControl : null}
            {showAspectRatioInFirstColumn ? aspectRatioControl : null}
            {showFastGenInFirstColumn ? fastGenControl : null}
          </div>
        ) : null}
        {isBelowMd ? null : stylesControl}
        <div className='flex flex-col gap-2 w-[300px]'>
          {seedControl}
          {motionLevelControl}
          {durationControl}
          {resolutionControl}
          {motionModeControl}
          {templateClientControl}
          {showCameraMovementInFirstColumn ? null : cameraMovementControl}
          {showAspectRatioInFirstColumn ? null : aspectRatioControl}
          {showFastGenInFirstColumn ? null : fastGenControl}
          {isBelowMd ? stylesControl : null}
          {showAPG ? APGControl : null}
        </div>
      </div>
      {useDialog ? (
        <Button variant='primary' className='w-full mb-4' onClick={() => setActivePopoverKey(null)}>
          OK
        </Button>
      ) : null}
    </div>
  )

  const fullSettingsTrigger = (
    <Button
      variant='outline'
      aria-label='Creation Setting'
      type='button'
      tooltip='Full Settings'
      tooltipProps={{
        align: 'start',
      }}
      className={cls(iconButtonStyle)}
      onClick={() => {
        setActivePopoverKey('full')
      }}
    >
      <IconSetting className='size-5 text-icon' />
    </Button>
  )

  const fullSettingProps = {
    trigger: fullSettingsTrigger,
    className: cls(popoverStyle, 'w-auto p-2 bg-surface-subdued max-w-max', useDialog && 'w-full h-[calc(100%-32px)]'),
    title: useDialog ? (
      <div className='text-heading-lg font-bold h-12 flex items-center pb-2'>Settings</div>
    ) : undefined,
    footer: useDialog ? null : undefined,
    open: getPopoverOpen('full'),
    onOpenChange: (open: boolean) => {
      setActivePopoverKey((old) => (old === 'full' && !open ? null : old))
    },
    children: fullSettingsControl,
  }

  return (
    <div className={cls('flex items-center gap-3', className)}>
      <FullSettingContainer {...fullSettingProps} />
      {showMotionMode && (
        <Popover
          popOnHover
          open={getPopoverOpen('motion-mode')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(iconButtonStyle, 'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex')}
              onMouseEnter={() => {
                setActivePopoverKey('motion-mode')
              }}
            >
              <IconVideoHighMotion className='size-5 text-icon' />
              <span className='px-1'>{motionModeValue === 'true' ? 'Standard' : 'Smooth'}</span>
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div>{motionModeControl}</div>
        </Popover>
      )}
      {showStyles && (
        <Popover
          popOnHover
          open={getPopoverOpen('styles')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued hidden md:flex',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('styles')
              }}
            >
              <div className='flex items-center'>
                <IconColors className='size-5 text-icon' />
                {style ? <span className='px-1'>{styleName}</span> : null}
              </div>
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div className='w-[300px]'>{stylesControl}</div>
        </Popover>
      )}
      {showDuration && (
        <Popover
          popOnHover
          open={getPopoverOpen('duration')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 px-2 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('duration')
              }}
            >
              <IconStopWatch className='size-5 text-icon' />
              <span className='px-1'>{settings?.duration ? `${settings.duration}s` : ''}</span>
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div>{durationControl}</div>
        </Popover>
      )}
      {showFakeDuration && (
        <Popover
          popOnHover
          open={getPopoverOpen('duration')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(iconButtonStyle, 'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex')}
              onMouseEnter={() => {
                setActivePopoverKey('duration')
              }}
            >
              <IconStopWatch className='size-5 text-icon-subdued' />
              <span className='px-1 text-text-subdued'>6s</span>
            </Button>
          }
          className={cls(popoverStyle, 'w-max text-body-md font-medium tracking-15')}
        >
          <div className='bg-surface-on-video text-text-on-color px-3 py-2'>
            Longer videos
            <br />
            coming soon
          </div>
        </Popover>
      )}
      {showAspectRatio && (
        <Popover
          popOnHover
          open={getPopoverOpen('aspect_ratio')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 px-2 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('aspect_ratio')
              }}
            >
              <IconPageSize className='size-5 text-icon' />
              <span className='px-1'>{String(settings?.aspect_ratio ?? DEFAULT_ASPECT_RATIO)}</span>
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div>{aspectRatioControl}</div>
        </Popover>
      )}
      {showFakeAspectRatio && (
        <Popover
          popOnHover
          open={getPopoverOpen('aspect_ratio')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(iconButtonStyle, 'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex')}
              onMouseEnter={() => {
                setActivePopoverKey('aspect_ratio')
              }}
            >
              <IconPageSize className='size-5 text-icon-subdued' />
              <span className='px-1 text-text-subdued'>{DEFAULT_ASPECT_RATIO}</span>
            </Button>
          }
          className={cls(popoverStyle, 'w-max text-body-md font-medium tracking-15')}
        >
          <div className='bg-surface-on-video text-text-on-color px-3 py-2'>
            More aspect ratios
            <br />
            coming soon
          </div>
        </Popover>
      )}
      {showResolution && (
        <Popover
          popOnHover
          open={getPopoverOpen('resolution')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 px-2 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued px-2 hidden md:flex',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('resolution')
              }}
            >
              <IconResolution className='size-5 text-icon' />
              <span className='px-1'>
                {currentCreationMode?.mode === CreationModeEnum.CreateImg
                  ? `${findNearestNumber(settings?.resolution ?? 0, resolutions)}p`
                  : isHD
                    ? 'HD'
                    : 'SD'}
              </span>
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div>{resolutionControl}</div>
        </Popover>
      )}
      {showCameraMovement && (
        <Popover
          popOnHover
          open={getPopoverOpen('camera-movement')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 px-2 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued p-0 hidden md:flex',
                settings?.camera_movement && settings?.camera_movement !== 'none' && 'px-2',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('camera-movement')
              }}
            >
              <IconRotate className='size-5 text-icon' />
              {settings?.camera_movement && settings?.camera_movement !== 'none' ? (
                <span className='px-1'>{cameraMovementMap[settings?.camera_movement]}</span>
              ) : null}
            </Button>
          }
          className={cls(popoverStyle, '')}
        >
          <div>{cameraMovementControl}</div>
        </Popover>
      )}
      {showAPG && (
        <Popover
          popOnHover
          open={getPopoverOpen('apg')}
          trigger={
            <Button
              variant='outline'
              aria-label='Creation Setting'
              type='button'
              className={cls(
                iconButtonStyle,
                // 'bg-surface-subdued hover:opacity-80 px-2 hidden md:flex',
                'bg-surface-subdued hover:bg-surface-semisubdued p-0 hidden md:flex',
                settings?.camera_movement && settings?.camera_movement !== 'none' && 'px-2',
              )}
              onMouseEnter={() => {
                setActivePopoverKey('apg')
              }}
            >
              <span className='px-2'>APG</span>
            </Button>
          }
          className={cls(popoverStyle, '')}
          onOpenChange={formatAPGValues}
        >
          <div>{APGControl}</div>
        </Popover>
      )}
      <Popover
        popOnHover
        open={getPopoverOpen('seed')}
        trigger={
          <Button
            variant='outline'
            aria-label='Creation Setting'
            type='button'
            className={cls(
              iconButtonStyle,
              // 'bg-surface-subdued hover:opacity-80 hidden md:flex',
              'bg-surface-subdued hover:bg-surface-semisubdued hidden md:flex',
              seedActive && 'bg-surface-active hover:bg-surface-active',
            )}
            onMouseEnter={() => {
              setActivePopoverKey('seed')
            }}
          >
            <IconDice className={cls('size-5 text-icon', seedActive && 'text-icon-interactive')} />
          </Button>
        }
        className={cls(popoverStyle, '')}
      >
        {seedControl}
      </Popover>
    </div>
  )
}
